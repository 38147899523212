<template>
    <b-overlay class="overlay-component" :show="isLoading" no-wrap />
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
export default {
    components: {
        BOverlay
    },
    props: ['isLoading']
}
</script>

<style lang="scss">
@import './index.scss'
</style>