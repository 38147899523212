<template>
  <div class="d-flex flex-column" style="gap: 23px" v-if="completedProps">
    <b-row
      align-h="start"
      v-for="(enroll, index) in completedProps"
      :key="index"
    >
      <b-col cols="auto">
        <b-link :to="`class-rooms/show-course/${enroll.course.id}`">
          <img
            width="45"
            height="45"
            class="course-image"
            :src="
              enroll.course.image && enroll.course.image.length
                ? enroll.course.image[0].path
                : imageSrc
            "
            alt="Example Image"
          />
        </b-link>
      </b-col>
      <b-col>
        <b-link :to="`class-rooms/show-course/${enroll.course.id}`">
          <b-row style="color: #5e5e5e">{{ enroll.course.name }}</b-row>
          <b-row style="color: #5e5e5e">{{
            enroll.course.lessons_count
          }}</b-row>
        </b-link>
      </b-col>
    </b-row>
  </div>
  <b-card-text class="mb-0" v-else>
    {{ $t("g.ThereIsCurrentlyNoCompletedCourses") }}
  </b-card-text>
</template>

<script>
import { BCardText, BLink, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
    BCardText,
    BLink,
  },
  props: ["completedProps"],
  data() {
    return {
      imageSrc: require("@/assets/dev/Rectangle 12.png"),
      plusIcon: require("@/assets/images/icons/plus.svg"),
    };
  },
};
</script>

<style scoped>
@import "./index.scss";
</style>
